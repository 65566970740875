
function Footer() {
	return (
		<div className={"bg-danger px-4 py-3 text-center text-light"}>
			<h4 className={"m-0"}>Copyright @ 2022 SLT</h4>
		</div>
	);
}

export default Footer;
